import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import ShopGrid from "../components/shopGrid"
import ShopBanner from "../components/shopBanner"
// import StockNotifier from "../components/stockNotifier"

const ProductSection = styled.section`
  background: var(--grey);
  padding: 2rem 0 8rem;
  a:hover {
    border-bottom: 0;
  }
  h1 {
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: 900;
    margin: 2rem 0;
    strong {
      font-weight: 900;
    }
  }
  .stock-heading {
    font-size: 3rem;
    margin: 4rem 0 0;
    padding-bottom: 0;
  }
`

const ButtonGrid = styled.div`
  .btn {
    font-size: 1.3rem;
    background: transparent;
    border: 1px solid #f3a2af;
    color: var(--black);
    &:hover {
      background: #f3a2af;
      color: var(--white);
    }
  }
  .active {
    background: #f3a2af;
    border: 1px solid #f3a2af;
    color: var(--white);
  }
  @media (max-width: 768px) {
    margin-top: 2.4rem;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.6rem;
    .btn {
      margin-top: 0;
    }
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`

const Loja = ({ data }) => {
  const [filterState, setFilterState] = useState("Todos")
  const [secondFilterState, setSecondFilterState] = useState("blank")
  const [products, setProducts] = useState([])
  const miniMagicKit = data.allShopifyCollection.edges
    .filter(el => el.node.handle === "magic-collection")[0]
    .node.products.filter(el => el.handle === "mini-starter-kit")[0]
  const sortByCategory = (a, b) => {
    if (a.productType < b.productType) {
      return -1
    }
    if (a.productType > b.productType) {
      return 1
    }
    return 0
  }
  useEffect(() => {
    let filteredProducts = []
    if (filterState === "Todos") {
      setSecondFilterState("blank")
      data.allShopifyCollection.edges.forEach(element => {
        element.node.products.forEach(product => {
          product = { ...product, productLine: element.node.handle }
          filteredProducts.push(product)
        })
      })
      setProducts(filteredProducts)
    } else {
      let singleCollection = data.allShopifyCollection.edges.filter(
        el => el.node.handle === filterState
      )[0].node.products
      if (secondFilterState !== "blank") {
        singleCollection = singleCollection.filter(
          el => el.productType === secondFilterState
        )
      }
      singleCollection = singleCollection.map(product => ({
        ...product,
        productLine: filterState,
      }))
      if (
        filterState === "magic-collection" &&
        secondFilterState === "Starter Kit"
      ) {
        singleCollection.push(miniMagicKit)
      }
      setProducts(singleCollection)
    }
  }, [filterState, secondFilterState])
  const productsInStock = products.filter(
    product =>
      product.shopifyId === "gid://shopify/Product/7072009388222" ||
      product.shopifyId === "gid://shopify/Product/6029788381374" ||
      product.shopifyId === "gid://shopify/Product/6585380241598" ||
      product.shopifyId === "gid://shopify/Product/6585390235838" ||
      product.shopifyId === "gid://shopify/Product/6585375064254"
  )
  const productsOutOfStock = products.filter(
    product =>
      product.shopifyId !== "gid://shopify/Product/7072009388222" &&
      product.shopifyId !== "gid://shopify/Product/6029788381374" &&
      product.shopifyId !== "gid://shopify/Product/6585380241598" &&
      product.shopifyId !== "gid://shopify/Product/6585390235838" &&
      product.shopifyId !== "gid://shopify/Product/6585375064254"
  )
  return (
    <Layout>
      <Seo
        title="Loja"
        description="Na nossa loja podes encontrar todos os produtos Boomsy: os Starter Kits, as Pestanas Magnéticas e o nosso Eyeliner Magnético."
      />
      <ProductSection>
        <div className="large-container">
          <ShopBanner />
          <h1>Loja</h1>
          <ul className="breadcrumb">
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>Loja</li>
          </ul>
          <ButtonGrid className="grid-3x">
            <button
              onClick={() => setFilterState("Todos")}
              className={filterState === "Todos" ? "btn active" : "btn"}
            >
              Todos
            </button>
            <button
              onClick={() => setFilterState("magic-collection")}
              className={
                filterState === "magic-collection" ? "btn active" : "btn"
              }
            >
              Magic Collection
            </button>
            <button
              onClick={() => setFilterState("magnetic-collection")}
              className={
                filterState === "magnetic-collection" ? "btn active" : "btn"
              }
            >
              Magnetic Collection
            </button>
          </ButtonGrid>
          <ButtonGrid
            className={filterState === "Todos" ? "hide grid-3x" : "grid-3x"}
          >
            <button
              onClick={() => setSecondFilterState("Starter Kit")}
              className={
                secondFilterState === "Starter Kit" ? "btn active" : "btn"
              }
            >
              Kits
            </button>
            <button
              onClick={() => setSecondFilterState("Pestanas")}
              className={
                secondFilterState === "Pestanas" ? "btn active" : "btn"
              }
            >
              Pestanas
            </button>
            <button
              onClick={() => setSecondFilterState("Eyeliner")}
              className={
                secondFilterState === "Eyeliner" ? "btn active" : "btn"
              }
            >
              Eyeliners
            </button>
          </ButtonGrid>
          {productsInStock.length > 0 && (
            <h2 className="stock-heading">Produtos em Stock</h2>
          )}
          <ul className="grid-4x" style={{ paddingTop: "4rem" }}>
            {productsInStock
              .sort(sortByCategory)
              .reverse()
              .map(product => (
                <ShopGrid product={product} key={product.shopifyId} />
              ))}
          </ul>
          {productsOutOfStock.length > 0 && (
            <h2 className="stock-heading">Produtos Esgotados</h2>
          )}
          <ul className="grid-4x" style={{ paddingTop: "4rem" }}>
            {productsOutOfStock
              .sort(sortByCategory)
              .reverse()
              .map(product => (
                <ShopGrid product={product} key={product.shopifyId} />
              ))}
          </ul>
        </div>
      </ProductSection>
    </Layout>
  )
}

export default Loja

export const query = graphql`
  {
    allShopifyCollection {
      edges {
        node {
          handle
          products {
            publishedAt
            title
            shopifyId
            description
            productType
            handle
            priceRangeV2 {
              minVariantPrice {
                amount
              }
            }
            variants {
              id
              title
              price
              availableForSale
              shopifyId
              selectedOptions {
                name
                value
              }
            }
            featuredImage {
              originalSrc
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
