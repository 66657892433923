import React, { useLayoutEffect, useRef } from "react"
import ShopifyBuy from "@shopify/buy-button-js"
import "./buybutton.css"

const buttonStyles = {
  "font-family": "Lato, sans-serif",
  padding: "10px 60px",
  display: "inline-block",
  background: "#000",
  color: "#fff",
  "text-transform": "uppercase",
  "font-size": "14px",
  "font-weight": "bold",
  cursor: "pointer",
  transition: "0.3s linear all",
  "margin-top": "0px",
  "line-height": "20px",
  "border-bottom": "0",
  border: "none",
  appearance: "none",
  "margin-left": "0",
  "border-radius": "0",
  ":hover": {
    background: "#e8b795",
    "border-bottom": "0",
  },
  ":active": {
    background: "#e8b795",
    "border-bottom": "0",
  },
  ":focus": {
    background: "#e8b795",
    "border-bottom": "0",
  },
}

const titleStyles = {
  "font-family": "'glacial_bold', sans-serif",
  "font-size": "28px",
  "font-weight": "bold",
  "text-transform": "uppercase",
  color: "#000",
  "margin-bottom": "20px",
}

const ShopifyBuyButton = ({ id, copy, className }) => {
  const btnEl = useRef(null)
  useLayoutEffect(() => {
    const client = ShopifyBuy.buildClient({
      domain: "loja.boomsy.pt",
      storefrontAccessToken: "e33c27948535ba158695aaf2a18ee3e4",
    })
    const ui = ShopifyBuy.UI.init(client)
    const actualId = id.slice(22)
    ui.createComponent("product", {
      id: actualId,
      node: btnEl.current,
      moneyFormat: "€{{amount}}",
      options: {
        product: {
          iframe: false,
          width: "100%",
          buttonDestination: "modal",
          contents: {
            img: false,
            title: false,
            price: false,
            variantTitle: false,
            options: false,
            imgWithCarousel: false,
            button: true,
          },
          text: {
            button: copy,
            outOfStock: "Esgotado",
          },
          styles: {
            buttonWrapper: {
              "margin-top": 0,
            },
            button: buttonStyles,
          },
        },
        modalProduct: {
          iframe: false,
          layout: "horizontal",
          width: "100%",
          contents: {
            img: false,
            imgWithCarousel: true,
            title: true,
            variantTitle: false,
            price: true,
            unitPrice: true,
            options: true,
            button: true,
            buttonWithQuantity: false,
            quantity: false,
            quantityIncrement: false,
            quantityDecrement: false,
            description: true,
          },
          order: [
            "img",
            "imgWithCarousel",
            "title",
            "variantTitle",
            "price",
            "options",
            "buttonWithQuantity",
            "button",
            "description",
          ],
          classes: {
            wrapper: "shopify-buy__modal-product-wrapper",
            hasImage: "has-image",
            button: "shopify-buy__btn",
          },
          buttonDestination: "cart",
          text: {
            button: "ADICIONAR",
            outOfStock: "ESGOTADO",
          },
          styles: {
            button: {
              "border-radius": "0",
              "font-weight": "bold",
            },
            title: titleStyles,
            price: {
              "font-family": "Lato, sans-serif",
              "font-size": "18px",
              color: "#000",
            },
            description: {
              "font-family": "Lato, sans-serif",
              "margin-top": "24px",
              "font-size": "16px",
              "line-height": "24px",
            },
          },
        },
        cart: {
          text: {
            title: "Carrinho",
            empty: "O teu carrinho está vazio.",
            currency: "EUR",
            notice:
              "Portes de envio serão acrescentados no processo de checkout.",
            closeAccessibilityLabel: "Fechar carrinho",
          },
          styles: {
            button: {
              "border-radius": "0",
              "background-color": "black",
              ":hover": {
                "background-color": "#e8b795",
              },
            },
          },
        },
        toggle: {
          contents: {
            count: true,
            icon: true,
            title: false,
          },
          styles: {
            toggle: {
              "background-color": "black",
              "border-radius": 0,
              padding: 20,
              ":hover": {
                "background-color": "#e8b795",
              },
            },
          },
        },
      },
    })
  }, [])
  return <div ref={btnEl} id={`button-${id}`}></div>
}

export default ShopifyBuyButton
