import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const shopBanner = () => {
  return (
    <div>
      <div className="mobile-only">
        <StaticImage
          src="../images/MagicMagnetic-Banner-MB.jpg"
          alt="Diferenças Magnetic Vs Magic Mobile"
          loading="eager"
          backgroundColor="black"
          layout="fullWidth"
          quality={90}
        />
      </div>
      <div className="mobile-none">
        <StaticImage
          src="../images/MagicMagnetic-Banner.jpg"
          alt="Diferenças Magnetic Vs Magic"
          placeholder="blurred"
          loading="eager"
          quality={90}
        />
      </div>
    </div>
  )
}

export default shopBanner
