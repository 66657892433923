import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import ShopifyBtn from "../components/shopify-btn"

const ProductDetail = styled.li`
  text-align: center;
  h2 {
    font-size: 1.6rem;
    padding-bottom: 0;
    color: var(--beige);
  }
  a {
    border-bottom: none;
  }
  .price {
    margin: 1.6rem 0;
    font-size: 1.6rem;
    color: rgb(209, 5, 5);
    font-weight: 700;
  }
  .std {
    color: rgb(0, 0, 0);
    font-weight: 400;
  }
`

const ShopGrid = ({ product }) => {
  const image = getImage(product.featuredImage.localFile)
  return (
    <ProductDetail key={product.shopifyId} style={{ position: "relative" }}>
      <GatsbyImage image={image} alt={product.title} />
      <div style={{ marginTop: "2rem" }}>
        <h2>{product.title}</h2>
        <div className="std price">
          {product.variants[0].compareAtPrice ? (
            <span className="crossed-price">
              {product.variants[0].compareAtPrice}€
            </span>
          ) : (
            ""
          )}
          {product.variants[0].price}€
        </div>
        <ShopifyBtn id={product.shopifyId} copy="VER PRODUTO" />
        {product.shopifyId ===
        "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3Nzg5MDUzMjk4NTQ=" ? (
          <div className="limited-edition">Limited Edition</div>
        ) : (
          ""
        )}
        {product.productType === "Starter Kit" &&
        product.productLine === "magic-collection" ? (
          <div className="new-product">Inclui Eyeliner</div>
        ) : (
          ""
        )}
        {product.handle === "mini-starter-kit" ? (
          <div className="new-product">Eyeliner à escolha</div>
        ) : (
          ""
        )}
      </div>
    </ProductDetail>
  )
}

export default ShopGrid
